<template>
    <div>
        <el-container>
            <el-header>
                <Header></Header>
            </el-header>
            <el-container>
                <el-aside style="width:200px">
                    <Aside
                            style="height: 100%;"
                            :MenuListData="MenuList"
                    >

                    </Aside>
                </el-aside>
                <el-main>
                    <router-View></router-View>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import Header from '../components/layout/Header'
    import Aside from '../components/layout/Aside'
    import {LoadMenu} from "../RequestPort/Login/LoginRequest";
    export default {
        components:{
            Aside,Header
        },
        name: "index",
        data(){
            return {
                MenuList:[

                ]
            }
        },
        methods:{

        },
        mounted(){

        },
        created() {
            // if(!this.$route.query.id){
            //     this.$message.error('请选择菜单模块')
            //     setTimeout(()=>{
            //         this.$router.push('/');
            //     },1000)
            // }else{
            //     let LoadMenuData = {
            //         userId:localStorage.getItem('userId'),
            //         permissionId:this.$route.query.id
            //     }
            //     LoadMenu(LoadMenuData).then((e)=>{
            //         this.MenuList = e.data
            //         console.log(e.data)
            //         let { routes } = this.$router.options;
            //         console.log(this.$router.options,'1')
            //         console.log(routes.find(r => r.path === this.$route.path),'-----')
            //         let routerData = routes.find(r => r.path === this.$route.path)
            //         e.data.forEach((item,index)=>{
            //             if(item.children){
            //                 console.log(item)
            //                 item.children.forEach((item1,index1)=>{
            //                     let RouterItem = {}
            //                     RouterItem.path = `${item1.url}`
            //                     RouterItem.name = item1.name
            //                     RouterItem.component = ()=>import(`.${item1.router}`)
            //                     routerData.children.push(RouterItem)
            //                 })
            //             }else{
            //                 let RouterItem = {}
            //                 RouterItem.path = `${item.url}`
            //                 RouterItem.name = item.name
            //                 RouterItem.component = ()=>import(`.${item.router}`)
            //                 routerData.children.push(RouterItem)
            //             }
            //             // this.$router.addRoute(RouterItem)
            //         })
            //         this.$router.addRoute(routerData)
            //     })
            // }
        }
    }
</script>
<style scoped>
    .el-aside {
        background-color: #00192E!important;
        color: #FFF;
        text-align: center;
    }
    .logoName{
        float:left;
        font-size:20px;
        height:100%;
        line-height:60px;
        font-weight: bold;
    }
    .el-main {
        background-color: #E9EEF3;
        color: #333;
        padding:0px;
    }
    .el-container {
        height:100%
    }
    .el-header, .el-footer {
        /* background-color: #406EB2; */
        color: #fff;
        background-color: #0c235b;
        text-align: center;
        line-height: 60px;
    }
</style>
